import packageJSON from "../../../../package.json";

export default {
  name: "Login",
  data() {
    return {
      property: {
        animation: {
          login: {
            isLoading: false,
            isDisable: false,
            isLoadingOtp: false,
          },
        },
        modal: {
          showModalInputOtp: false,
        },
      },
      form: {
        userCode: "",
        password: "",
      },
      brand_image: "",
      version: "",
      validationDomain: "",
      login_page_icon: process.env.VUE_APP_LOGIN_PAGE_ICON,
      verificationOtp: "",
      xTokenId: "",
    };
  },
  mounted() {
    this.version = packageJSON.version;
    this.getAsset();
  },
  methods: {
    async getAsset() {
      let loading = false;
      let session = null;
      while (!session && !loading) {
        loading = true;
        console.log("fetch asset");
        session = sessionStorage.getItem("@vue-session/asset-bundle");
        await this.simpleWait(1000);
        loading = false;
      }
      this.brand_image = JSON.parse(window.atob(session)).logoImg;
    },
    async fetchConfiguaration(tokenId) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl: "fe-configuration",
          header: {
            "x-token-id": tokenId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          sessionStorage.setItem(
            "@vue-session/config-bundle",
            this.encryptBASE64(JSON.stringify(resp.data.data))
          );
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async fetchMenuAccess(response) {
      this.property.animation.login.isLoading = true;
      this.property.animation.login.isDisable = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user-role/menu-link/" + response.data.data.roleId,
          header: {
            "x-token-id": response.data.data.tokenId,
          },
          endPoint: "user-management",
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp.data);
          const map = new Map(Object.entries(resp.data.data));
          console.log(map);
          sessionStorage.setItem(
            "@vue-session/auth-bundle",
            this.encryptBASE64(JSON.stringify(response.data.data))
          );
          sessionStorage.setItem(
            "@vue-session/access-bundle",
            this.encryptBASE64(JSON.stringify(Array.from(map)))
          );
          const notif = this.$buefy.toast.open({
            message: "Berhasil Login !",
            type: "is-success",
            duration: 1000,
            position: "is-top",
            queue: false,
          });
          notif.$on("close", () => {
            window.location.reload();
          });
        } else {
          this.$buefy.notification.open({
            message: `<b>Failed to retrieve the Data Menu !</b> <br/> ${resp.data.message}`,
            position: "is-top-right",
            type: "is-danger",
            hasIcon: true,
            queue: false,
          });
        }
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.notification.open({
            message: error.response
              ? `Failed to retrieve the Data Menu ! : ${error.response.statusText}`
              : `<b>Failed to retrieve the Data Menu !</b> <br/> Terjadi Kesalahan !`,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: true,
            queue: false,
          });
        }, 1000);
      } finally {
        await this.simpleWait(1500);
        this.property.animation.login.isLoading = false;
        this.property.animation.login.isDisable = false;
      }
    },
    async handleSubmitLogin() {
      const payloadHeader = {
        userCode: this.form.userCode,
        password: this.form.password,
        channelId: "web",
        isUserTellerPojok: false,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        if (this.form.username === "" || this.form.password === "") {
          return;
        } else {
          this.property.animation.login.isLoading = true;
          this.property.animation.login.isDisable = true;
          try {
            const resp = await this.$store.dispatch(
              "POST_LOGIN_DEV",
              payloadHeader
            );
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.fetchConfiguaration(resp.data.data.tokenId);
                if (resp.data.message !== "SUCCESS") {
                  this.$buefy.dialog.confirm({
                    title: "Konfirmasi",
                    message: `${resp.data.message}, silahkan update password untuk memperpanjang masa aktif !`,
                    confirmText: "Lanjut Login",
                    type: "is-info",
                    hasIcon: true,
                    canCancel: false,
                    closeOnConfirm: false,
                    onConfirm: async (value, { close }) => {
                      this.fetchMenuAccess(resp);
                      close();
                    },
                  });
                } else {
                  this.fetchMenuAccess(resp);
                }
              } else {
                this.$buefy.notification.open({
                  message: `<b>Gagal !</b> <br/> ${resp.data.message}`,
                  position: "is-top-right",
                  type: "is-danger",
                  hasIcon: true,
                  queue: false,
                });
              }
            }, 1000);
          } catch (error) {
            setTimeout(() => {
              console.log(error.response);
              this.$buefy.notification.open({
                message: error.response
                  ? `${error.response.statusText}`
                  : `<b>Gagal !</b> <br/> Terjadi Kesalahan !`,
                position: "is-bottom-right",
                type: "is-danger",
                hasIcon: true,
                queue: false,
              });
            }, 1000);
          } finally {
            setTimeout(() => {
              this.property.animation.login.isLoading = false;
              this.property.animation.login.isDisable = false;
            }, 1000);
          }
        }
      }
    },
  },
};
